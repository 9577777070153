import { type ErrorResponse } from "@remix-run/react";
import { ErrorContainer } from "./ErrorContainer";
import { ProblemDetail } from "./ProblemDetail";

export function UnknownProblem({
  error,
  problem,
}: {
  error?: ErrorResponse;
  problem?: BlankProblem | UnknownProblem;
}) {
  const title =
    problem?.title || error?.statusText || "Sorry, we've hit a problem";

  return (
    <ErrorContainer
      image={
        <img
          alt=""
          src="/radbra.webp"
          className="tw-size-60 -tw-rotate-6 tw-mb-6 tw-border-b-4 tw-border-b-pink-600 tw-rounded-sm"
        />
      }
      title={title}
    >
      <ProblemDetail detail={problem?.detail} />
    </ErrorContainer>
  );
}
